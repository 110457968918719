<template>
  <div class="footer">
    <el-row :gutter="30" type="flex" justify="space-between">
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="show-company">
        <div class="flex" style="padding-left: 110px;">
          <img src="@/assets/images/antail.png" alt="" />
          <p class="show-name">安泰乐（厦门）集团有限公司</p>
        </div>
          
        <p class="show-intro">
          安泰乐集团成立于2012年，中国总部位于厦门。是一家专注于中国生命关怀产业，为生命纪念馆提供设计、装修、管理、服务，一站式解决方案的专业化管理服务公司。
        </p>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="show-memorials">
        <div class="flex">
          <p class="show-name">安泰乐生命纪念馆</p>
        </div>
        <el-row :gutter="10" class="show-list">
          <el-col :span="12" v-for="(item, index) in memorials" :key="index">
            <div class="show-item">
              <img src="@/assets/images/hallw.png" alt="" />
              <div>
                <p class="show-tip">{{ item.address }}</p>
                <p>{{ item.name }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="show-app">
        <div class="flex">
          <p class="show-name">安泰乐生命纪念馆</p>
        </div>
        <div class="show-er">
          <div>
            <img
              src="https://atlimg.oss-cn-hangzhou.aliyuncs.com/web/home/whcbqhn.png"
              alt=""
            />
            <p>
              <img src="@/assets/images/WeChatIcon.png" alt="" />
              关注公众号
            </p>
          </div>
          <div>
            <img
              src="@/assets/images/appLoad.png"
              alt=""
              style="width:140px;height:140px;"
            />
            <p>
              <img src="@/assets/images/app.png" alt="" />
              安泰乐app下载
            </p>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="show-address">
        <div class="address">
          <img src="@/assets/images/clouds.png" alt="" />
          <div>
            <p class="show-name">云中祠信息科技</p>
            <p>地址：中国福建省厦门市湖里区同安商务大厦1号楼9层</p>
            <p>电话：0592-5539618</p>
            <p>邮件：mail@atlgroup-asia.com</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="row">
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank" >
          <p class="show-tip">©️2021ATL Group.All rights reserved</p>
        <p class="show-tip">闽ICP备16020886号-3</p>
        </a>
      </div>
      <div class="show-num">
        <img src="@/assets/images/embassy.png" alt="" />
        <div>
          <p>{{ toThousands(totalNum || 0) }}</p>
          <p class="show-tip">已建馆人数</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "customFooter",
  props: {},
  data() {
    return {
      totalNum: 0,
      memorials: [
        { name: "天竺生命纪念馆", address: "福建晋江" },
        { name: "南宝生命纪念馆", address: "江西南昌" },
        { name: "天王生命纪念馆", address: "福建古田" },
        { name: "圆觉生命纪念馆", address: "福建晋江" },
        { name: "安泰乐生命纪念馆", address: "福建晋江" },
        { name: "天竺生命纪念馆", address: "湖北孝感" },
      ],
    };
  },
  mounted() {
    this.$store
      .dispatch("selectOfficialWebsiteAtlTime", {
        // isCommend: 1,
        isFindCommend:1,
        livingState:1,
        page: { pageNum: 1, pageSize: 15 },
      })
      .then((res) => {
        this.totalNum = res.data.total + 10000;
      })
      .catch(() => {});
  },
  methods: {
    toThousands(num) {
      num = (num || 0).toString();
      let number = 0,
        floatNum = "",
        intNum = "";
      // 判断是否有小数位，有则截取小数点后的数字
      if (num.indexOf(".") > 0) {
        number = num.indexOf("."); // 获取小数点出现的位置
        floatNum = num.substr(number); // 截取arr.substr(form, length)
        intNum = num.substring(0, number); // 截取arr.substring(start, end)
      } else {
        intNum = num;
      }
      let result = [],
        counter = 0;
      intNum = intNum.split("");
      // 利用3的倍数，向数组插入','
      for (let i = intNum.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(intNum[i]);
        if (!(counter % 3) && i != 0) {
          result.unshift(",");
        }
      }
      return result.join("") + floatNum || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #fff;
  text-align: left;
  padding: 45px 30px 20px;
  color: #999;
  font-size: 10px;
  .flex {
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    img {
      position: absolute;
      left: 0;
      width: 92px;
    }
  }
  > div {
    flex-wrap: wrap;
  }
}
.show-company {
  img {
    width: 92px;
    height: 68px;
  }
  .show-intro {
    color: #999;
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 90px;
    line-height: 24px;
    // letter-spacing: 2px;
  }
}

.show-memorials {
  flex: 1;
  .show-list {
    margin-top: 10px;
  }
  .show-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    
    p:last-child {
      color: #caa774;
      font-size: 10px;
    }
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-right: 10px;
      opacity: 0.7;
    }
  }
}
.show-app {
  .show-name{
    color: transparent;
  }
  .show-er {
    display: flex;
    align-items: center;
    > div {
      margin-right: 40px;
    }
    img {
      width: 150px;
      height: 150px;
    }
    p {
      color: #020202;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        height: 23px;
        margin-right: 5px;
        object-fit: contain;
      }
    }
  }
}
.show-address {
  .address {
    display: flex;
    line-height: 30px;
    img {
      width: 134px;
      height: 102px;
      margin-right: 15px;
    }
  }
}
.show-name {
  color: #333;
  font-size: 16px;
  margin: 5px 0 10px 0;
  // font-weight: bold;
}
.show-tip {
  font-size: 10px;
  color: #999;
  // line-height: 30px;
  padding-bottom: 5px;
  // letter-spacing: 2px;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .show-num {
    // margin-top: 30px;
    display: flex;
    // justify-content: center;
    align-items: center;
    color: #333;
    font-size: 14px;
    padding-right: 115px;
    img {
      width: 80px;
      height: 58px;
      margin-right: 35px;
    }
  }
}
</style>
