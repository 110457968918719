import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', 
    name: 'index',
    component: () => import('@/views/Home/index')
  },
  {
    path: '/mindex', 
    name: 'mindex',
    component: () => import('@/views/Home/mindex')
  },
  {
    path: '/login', 
    name: 'login',
    component: () => import('@/views/Login/index')
  },
  {
    path: '/register', 
    name: 'register',
    component: () => import('@/views/Login/Register/index')
  },
  {
    path: '/findPassword', 
    name: 'findPassword',
    component: () => import('@/views/Login/FindPassword/index')
  },
  {
    path: '/bindPage', 
    name: 'bindPage',
    component: () => import('@/views/Login/BindPage/index')
  },
  {
    path: '/agreement', 
    name: 'agreement',
    component: () => import('@/views/Agreement/index')
  },
  {
    path: '/appPage', 
    name: 'appPage',
    component: () => import('@/views/AppPage/index')
  },
  {
    path: '/timePeriod', 
    name: 'timePeriod',
    component: () => import('@/views/TimePeriod/index')
  },
  {
    path: '/preview', 
    name: 'preview',
    component: () => import('@/views/Preview/index')
  },
  {
    path: '/guidance', 
    name: 'guidance',
    component: () => import('@/views/mobilePage/guidance/index')
  },
  {
    path: '/indexEn', 
    name: 'IndexEn',
    component: () => import('@/views/englishPage/index')
  },
  {
    path: '/404',
    component: () => import('@/views/ErrorPage/errorPage.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]


const router = new VueRouter({
  // mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   const domain = location.hostname.replace(/\.test\.cnxieku\.com/g, '')
//   // const domain = location.hostname.replace(/\.cnxieku\.com/g, '')
//   if (domain && domain != 'www' && domain != 'localhost' && domain != 'market'
//     && JSON.stringify(to.query) == '{}') {
//     // 要跳转店铺的独有域名主页
//     getShopIdByDomain({ domain }).then(res => {
//       const shopId = res.data
//       if (shopId) {
//         next('/shop/shopIndex?shopId=' + shopId)
//       } else {
//         location.href = new Vue().DOMAIN_URL
//       }
//     }).catch(err => {
//       location.href = new Vue().DOMAIN_URL
//     })
//   } else {
//     if (to.query.t) {
//       store.commit('SET_TOKEN', to.query.t)
//       store.dispatch('GetUserInfo')
//     }
//     next()
//   }
// })

router.afterEach((to, from) => {
  window.scrollTo(0, 0) // 解决页面跳转页面不在顶端
  console.log(to,from)
  if (to.query.t) {
    let newQuery = JSON.parse(JSON.stringify(to.query))
    delete newQuery.t
    router.replace({ query: newQuery })
  }
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isChunkLoadFailed) {
    router.replace(targetPath)
  }
})


export default router
