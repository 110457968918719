import Vue from "vue";
import Vuex from "vuex";

// import { login, loginPhone, getUserInfo, accountLogin } from '@/api/login'
import {
  selectOfficialWebsiteAtlTime,
  pwdLogin,
  verifyCodeLogin,
  getUserInfo,
  loginOut
} from "@/api/index";
import { getToken, setToken } from "@/utils/auth";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    token: localStorage.getItem("token")
      ? localStorage.getItem("token")
      : getToken(),
    username: localStorage.getItem("username")
      ? localStorage.getItem("username")
      : "",
    usericon: localStorage.getItem("usericon")
      ? localStorage.getItem("usericon")
      : "",
    userid: localStorage.getItem("userid")
      ? localStorage.getItem("userid")
      : "",
    shopName: localStorage.getItem("shopName")
      ? localStorage.getItem("shopName")
      : "",
    email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
    cartnum: localStorage.getItem("cartnum")
      ? localStorage.getItem("cartnum")
      : "0",
    messagenum: localStorage.getItem("messagenum")
      ? localStorage.getItem("messagenum")
      : "0",
    typeList: localStorage.getItem("typeList")
      ? localStorage.getItem("typeList")
      : "",
    searchType: localStorage.getItem("searchType")
      ? localStorage.getItem("searchType")
      : "0",
    productType: localStorage.getItem("productType")
      ? localStorage.getItem("productType")
      : "3",
    mallLogo: localStorage.getItem("mallLogo") || null,
    cheapHomeLogo: localStorage.getItem("cheapHomeLogo") || null,
    rentHomeLogo: localStorage.getItem("rentHomeLogo") || null,
    mallName: localStorage.getItem("mallName") || null,
    mallIcp: localStorage.getItem("mallIcp") || null,
    isRegister: localStorage.getItem("isRegister") || null,
    companyName: localStorage.getItem("companyName") || null,
    mallTitle: localStorage.getItem("mallTitle") || null,
    mallDesc: localStorage.getItem("mallDesc") || null,
    mallKeywords: localStorage.getItem("mallKeywords") || null,
  },
  mutations: {
    SET_TOKEN(state, token) {
      // 存储token
      state.token = token;
      localStorage.setItem("token", token);
      setToken(token);
    },
    SET_USERICON(state, usericon) {
      // 存储用户名
      state.usericon = usericon;
      localStorage.setItem("usericon", usericon);
    },
    SET_NAME(state, username) {
      // 存储用户名
      state.username = username;
      localStorage.setItem("username", username);
    },
    SET_USERID(state, userid) {
      // 存储用户名
      state.userid = userid;
      localStorage.setItem("userid", userid);
    },
    SET_SEARCH_TYPE(state, searchType) {
      // 设置搜索条件
      state.searchType = searchType;
      localStorage.setItem("searchType", searchType);
    },
    SET_PRODUCT_TYPE(state, productType) {
      //商品类型
      state.productType = productType;
      localStorage.setItem("productType", productType);
    },
    setshopName(state, shopName) {
      // 存储店铺名
      state.shopName = shopName;
      localStorage.setItem("shopName", shopName);
    },
    delshopName(state) {
      // 删除店铺名
      state.shopName = "";
      localStorage.removeItem("shopName");
    },
    setEmail(state, email) {
      // 存储用户名
      state.email = email;
      localStorage.setItem("email", email);
    },
    delEmail(state) {
      // 删除用户名
      state.email = "";
      localStorage.removeItem("email");
    },
    SET_CART_NUM(state, num) {
      // 购物车数量
      state.cartnum = num;
      localStorage.setItem("cartnum", num);
    },
    DEL_CART_NUM(state) {
      // 购物车数量
      state.cartnum = 0;
      localStorage.removeItem("cartnum");
    },
    SET_MESSAGE_NUM(state, num) {
      // 消息未读数量
      state.messagenum = num;
      localStorage.setItem("messagenum", num);
    },
    DEL_MESSAGE_NUM(state) {
      // 消息未读数量
      state.messagenum = 0;
      localStorage.removeItem("messagenum");
    },
    setGoodsTypeList(state, list) {
      // 传给确认订单页面的参数
      state.typeList = list;
      localStorage.setItem("typeList", list);
    },
    SET_MALL_LOGO(state, logo) {
      // 商城logo
      state.mallLogo = logo;
      localStorage.setItem("mallLogo", logo);
    },
    SET_RENT_LOGO(state, logo) {
      // 方便租logo
      state.rentHomeLogo = logo;
      localStorage.setItem("rentHomeLogo", logo);
    },
    SET_CHEAP_LOGO(state, logo) {
      // 便宜购logo
      state.cheapHomeLogo = logo;
      localStorage.setItem("cheapHomeLogo", logo);
    },
  },
  actions: {
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        console.log("======",userInfo)
        pwdLogin(userInfo)
          .then((response) => {
            console.log('response=====',response)
            localStorage.setItem('Atoken',response.data.token)
            let date =new Date()
            localStorage.setItem('time',date.getTime())
            const data = response.data;
            const tokenStr = data.token;
            commit("SET_TOKEN", tokenStr);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    verifyCodeLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        verifyCodeLogin(userInfo)
          .then((response) => {
            const data = response.data;
            const tokenStr = data.token;
            commit("SET_TOKEN", tokenStr);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((res) => {
            const data = res.data;
            commit("SET_NAME", data.nickName);
            commit("SET_USERID", data.id);
            commit("SET_USERICON", data.avaterImageUrl);
            // debugger
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    selectOfficialWebsiteAtlTime({ commit }, obj) {
      console.log("commit", commit);
      return new Promise((resolve, reject) => {
        selectOfficialWebsiteAtlTime(obj)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // // 账号登录
    // Login({ commit }, userInfo) {
    //   return new Promise((resolve, reject) => {
    //     login(userInfo).then(response => {
    //       const data = response.data
    //       const tokenStr = data.tokenHead + data.token
    //       commit('SET_TOKEN', tokenStr)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // // 手机号登录
    // LoginPhone({ commit }, userInfo) {
    //   return new Promise((resolve, reject) => {
    //     loginPhone(userInfo).then(response => {
    //       const data = response.data
    //       const tokenStr = data.tokenHead + data.token
    //       commit('SET_TOKEN', tokenStr)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // // 账号信息登录
    // AccountLogin({ commit }, params) {
    //   return new Promise((resolve, reject) => {
    //     accountLogin(params).then(response => {
    //       const data = response.data
    //       const tokenStr = data.tokenHead + data.token
    //       commit('SET_TOKEN', tokenStr)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // // 获取用户信息
    // GetUserInfo({ commit, state }) {
    //   console.log(state)
    //   return new Promise((resolve, reject) => {
    //     getUserInfo().then(res => {
    //       const data = res.data
    //       commit('SET_NAME', data.userName)
    //       commit('SET_USERID', data.id)
    //       commit('SET_USERICON', data.headPic)
    //       // debugger
    //       resolve(res)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // 前端 登出
    LogOut({ commit }) {
      return new Promise((resolve,reject) => {
        loginOut()
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_NAME", "");
            commit("SET_USERICON", "");
            localStorage.removeItem("token");
            localStorage.removeItem("Atoken");
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});

export default store;
