import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import VueLazyload from "vue-lazyload";
import "lib-flexible";
import "@/assets/styles/element-variables.scss";
import animated from "animate.css"; // npm install animate.css --save安装，在引入
import CustomHeader from "@/components/CustomHeader";
import CustomFooter from "@/components/CustomFooter";
import { Swipe, SwipeItem } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(animated);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueLazyload, {
  preLoad: 1.2,
  error: require("@/assets/images/antail.png"),
  loading: require("@/assets/images/antail.png"),
  attempt: 1,
});
Vue.directive("class", {
  inserted: function(el, binding) {
    console.log("el", binding);
    // 聚焦元素
    binding.addClass = () => {
      const { top } = el.getBoundingClientRect();
      const h =
        document.documentElement.clientHeight || document.body.clientHeight;
      if (top < h - 200) {
        el.className = binding.value;
        if (binding.addClass) {
          window.removeEventListener("scroll", binding.addClass);
        }
      } else {
        if (binding.addClass) {
          window.removeEventListener("scroll", binding.addClass);
        }
      }
    };

    window.addEventListener("scroll", binding.addClass, true);

    binding.addClass();
  },
  unbind: function(el, binding) {
    if (binding.addClass) {
      window.removeEventListener("scroll", binding.addClass);
      console.log("取消事件绑定");
    }
  },
});

Vue.component("CustomHeader", CustomHeader);
Vue.component("CustomFooter", CustomFooter);
import common from "@/utils/commonFunctions";

Vue.prototype.common = common; // 公共函数
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
