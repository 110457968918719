import request from "@/utils/request";
// 协议
export function getProtocol(params) {
  return request({
    url: "/atlProtocol/getProtocol",
    method: "get",
    params,
  });
}
// 获取官网时光纪
export function selectOfficialWebsiteAtlTime(data) {
  return request({
    url: "/atlTime/selectOfficialWebsiteAtlTime",
    method: "post",
    data,
  });
}
// 随即推荐三个时光纪
export function selectRandomAtlTime(params) {
  return request({
    url: "/atlTime/selectRandomAtlTime",
    method: "post",
    params,
  });
}
// 发送验证码
export function sendSms(params) {
  return request({
    url: "/sms/send-verify-code",
    method: "post",
    params,
  });
}
//注册
export function register(params) {
  return request({
    url: "/register/register-mobile",
    method: "post",
    params,
  });
}
// 手机号/密码登录
export function pwdLogin(params) {
  return request({
    url: "/login/pwdLogin",
    method: "post",
    params,
  });
}
// 手机号验证码登录
export function verifyCodeLogin(data) {
  return request({
    url: "/login/verifyCodeLogin",
    method: "post",
    data,
  });
}

// 获取用户信息
export function getUserInfo(params) {
  return request({
    url: "/atlUser/getUserInfo",
    method: "post",
    params,
  });
}
// 微信二维码地址
export function wechatLogin(params) {
  return request({
    url: "/wx/getQrcodeUrl",
    method: "get",
    params,
  });
}
// 微信二维码回调地址
export function getQrcodeAndState(params) {
  return request({
    url: "/wx/getQrcodeAndState",
    method: "get",
    params,
  });
}
// 新用户微信扫码登录，绑定手机号密码

export function wechatBindPhone(data) {
  return request({
    url: "/wx/insertAtlUserByWxLogin",
    method: "POST",
    data,
  });
}


// 退出登录
export function loginOut(params) {
  return request({
    url: "/login/loginOut",
    method: "post",
    params,
  });
}
// 忘记密码
export function forgetPassword(params) {
  return request({
    url: "/atlUser/forgetPassword",
    method: "post",
    params,
  });
}
// 获取时光纪详情
export function timeDetails(params) {
  return request({
    url: "/atlTime/timeDetails",
    method: "post",
    params,
  });
}
// 是否是本人
export function isMyself(params) {
  return request({
    url: "/atlUser/isMyself",
    method: "post",
    params,
  });
}
// 增加阅读量
export function addReadCount(params) {
  return request({
    url: "/home/addReadCount",
    method: "post",
    params,
  });
}
