import axios from 'axios'
import store from '../store'
import router from '../router'
import { Message } from 'element-ui'

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'development'?'/api':'http://appapi.antaile.net',
  baseURL: 'https://appapi.antaile.net',
  // baseURL: 'http://192.168.31.27:8088',
  timeout: 60000 // 请求超时时间
})
// request拦截器 
service.interceptors.request.use(config => {
  if (store.state.token) {
    config.headers['token'] = store.state.token
    config.headers['content-type'] = "application/json"
    // config.headers['content-type'] = "application/x-www-form-urlencoded"
    // Loading.service() // 请求开始 开启loading
  }
  return config
}, error => {
  // Do something with request error
  window.console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * status为非200是抛错 可结合自己业务进行修改
     */
    
    const res = response.data
    console.log("res",res.status !== 200)
    if (res.status != 200) {
      console.log("res",res.status)
      // 401:未登录;
      if (res.status === 401 || res.status === 403) {
        store.commit('SET_TOKEN', '')
        router.replace({
          name: 'login',
          query: { redirect: router.currentRoute.fullPath } // 登录后再跳回此页面时要做的配置
        })
      }
      return Promise.reject(res)
    } else {
      return response.data
    }
  },
  error => {
    console.log("err",error)
    // 错误信息状态码提示
    if (error.response) {
      switch (error.response.status) {
        case 400:
          Message.error('数据请求错误')
          break
        case 401:
          // Message.error('未授权，请重新登录')
          store.commit('SET_TOKEN', '')
          router.replace({
            name: 'login',
            query: { redirect: router.currentRoute.fullPath } // 登录后再跳回此页面时要做的配置
          })
          break
        case 403:
          Message.error('拒绝访问')
          break
        case 404:
          Message.error('请求错误，未找到该资源')
          break
        case 405:
          Message.error('请求方法未允许')
          break
        case 408:
          Message.error('请求超时')
          break
        case 500:
          if(error.response.data.message){
            Message.error(error.response.data.message)
          }else {
            Message.error('服务器异常')
          }
          break
        case 501:
          Message.error('网络未实现')
          break
        case 502:
          Message.error('网络错误')
          break
        case 503:
          Message.error('服务不可用')
          break
        case 504:
          Message.error('网络超时')
          break
        case 505:
          Message.error('http版本不支持该请求')
          break
      }
    }else {
      // Message.warning(error)
    }
    // window.console.log('err' + error)
    // Message.error('服务器开小差了，请稍后重试')
    return Promise.reject(error)
  }
)

export default service
