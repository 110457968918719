<template>
  <div class="header">
    <div style="display: flex; flex: 1" v-if="deviceType == 2">
      <router-link :to="{ name: 'index' }" class="show-logo">
        <img src="@/assets/images/logo.png" alt="" class="logo" />
        <span class="show-title">安泰乐生命纪念馆</span>
      </router-link>
      <ul class="right-ul">
        <li>
          <router-link :to="{ name: 'timePeriod' }">纪念馆列表</router-link>
        </li>
        <li><router-link :to="{ name: 'appPage' }">APP下载</router-link></li>
      </ul>
    </div>
    <img src="@/assets/images/logo.png" alt="" class="logo" v-else />
    <ul class="right-ul">
      <!-- <li v-if="$store.state.token" > -->
        <li v-if="token!=''" >
         <el-dropdown trigger="hover">
      <span class="el-dropdown-link">
        <div class="nameBox">
          <img
          :src="common.showPic($store.state.usericon)"
          :onerror="common.defaultAvatar()"
          alt=""
          class="avatar"
          
        />
       <div class="names">{{ $store.state.username }}</div>
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="goVipVue">会员中心</el-dropdown-item>
        <el-dropdown-item @click.native="signOut">退出登录</el-dropdown-item>
        <!-- <el-dropdown-item icon="el-icon-circle-plus-outline">螺蛳粉</el-dropdown-item>
        <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-check">蚵仔煎</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
        
      </li>
      <li v-else>
        <div class="hiddenDiv">
          <img
          src="@/assets/images/Signin.png"
          alt=""
          class="avatar"
        />
        </div>
        <router-link :to="{ name: 'login' }">登录</router-link>
      </li>
      <li v-if="deviceType == 2">
        <router-link :to="{ path: '/agreement', query: { type: 3 } }"
          >用户协议</router-link
        >
      </li>
      <li v-if="deviceType == 2">
        <router-link :to="{ path: '/agreement', query: { type: 1 } }"
          >隐私协议</router-link
        >
      </li>
      <li>
        <el-dropdown trigger="hover">
      <span class="el-dropdown-link">
        <div style="color: #ccc;font-size: 16px;">中国（CN）</div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="goEn">新加坡（SG）</el-dropdown-item>
        <el-dropdown-item @click.native="goEn">美国（US）</el-dropdown-item>
        <!-- <el-dropdown-item icon="el-icon-circle-plus-outline">螺蛳粉</el-dropdown-item>
        <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item>
        <el-dropdown-item icon="el-icon-circle-check">蚵仔煎</el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "customHeader",
  data() {
    return {
      deviceType: null,
      token:''
    };
  },
  props: {},
  mounted() {
   if(localStorage.getItem('token')){
      this.token=localStorage.getItem('token')
   }
    if (this.common.isMobile()) {
      this.deviceType = 1;
      this.$router.push('/mindex')
    } else {
      this.deviceType = 2;
      // this.$router.push('/')
    }
  },

  methods: {
    //去英文
    goEn(){
      // window.open('https://us.atl-life.com')
      // window.close();
      this.$router.push("/indexEn")
    },
    //跳转到会员中心
    goVipVue(){
      // window.location.href="https://www.atl-life.com/userCenter/?token=" + localStorage.getItem('Atoken')
      // window.location.href="http://192.168.31.68:9528/index?token=" + localStorage.getItem('Atoken')
      // window.open("http://192.168.31.18:9528/index?token=" + localStorage.getItem('Atoken'));


      window.open("https://www.atl-life.com/userCenter/?token=" + localStorage.getItem('Atoken'));
      //  window.open("http://192.168.31.25:9528/index?token=" + localStorage.getItem('Atoken'));
      //  window.open("http://192.168.31.25:8082/index?token=" + localStorage.getItem('Atoken'));
      window.close();
    },
    // 退出账号
    signOut() {
      this.$confirm("您是否要退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
        type: "warning",
      }).then(() => {
        window.localStorage.clear();
        this.token=''
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "index" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #181f2a;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc((100% - 1200px) / 2);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  a {
    color: #ccc;
  }
  > .logo {
    margin-left: 38px;
  }
  .show-logo{
    display: flex;
    align-items: center;
  }
  .logo {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    object-fit: contain;
  }
  .show-title{
        -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #b8832d, #f6c943);
    color: linear-gradient(to right, #b8832d, #f6c943);
    border-bottom: 1px solid linear-gradient(to right, #b8832d, #f6c943);
    font-size: 18px;
    margin-right: 50px;
    font-weight: bold;
  }
}
.header .right-ul {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  > li {
    margin-right: 25px;
    color: #ccc;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
   
  
  }
  .nameBox{
    display: flex;
    align-items: center;
    color: #ccc;
    font-size: 16px;
    cursor:pointer;
  }
}
::v-deep .el-dropdown-menu__item:not(.is-disabled):hover, .el-dropdown-menu__item:focus{
  background-color: #FFF;
  font-weight: 600;
    color: #181f2a;
}

</style>