import { formatDate } from './date'

export default {
  isMobile() {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
  },
  // // 设置默认图片
  defaultImg() {
    return 'this.src="' + require('@/assets/images/antail.png') + '"'
  },
  // defaultAd() {
  //   return 'this.src="' + require('@/assets/images/bannerthree.png') + '"'
  // },
  defaultAvatar() {
    return 'this.src="' + require('@/assets/images/Signin.png') + '"'
  },
  // defaultDraw() {
  //   return 'this.src="' + require('@/assets/draw/banner.png') + '"'
  // },
  primaryColor() {
    return '#41B9B2'
  },
  splitString(value) {
    let flag = ''
    if (!value) {
      return
    }
    if(value.indexOf(',')!=-1){
      flag = ','
    }else {
      flag = ' '
    }
    let arr = value.split(flag)
    return arr
  },
  // 金额显示过滤 两位小数点，不足补0
  priceFilter(value) {
    if (!value) {
      return '0.00'
    }
    var valueStr = Math.round(parseFloat(value) * 100) / 100
    var xsd = valueStr.toString().split('.')
    if (xsd.length == 1) {
      valueStr = valueStr.toString() + '.00'
      return valueStr
    }
    if (xsd.length > 1) {
      if (xsd[1].length < 2) {
        value = value.toString() + '0'
      }
      return value
    }
  },
  renderTime(date) {
    if (!date) {
      return
    }
    var dateee = new Date(date).toJSON()
    return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
  },
  formatTime(value,format) {
    let formatStr = ''
    if (value) {
      let date = new Date(value)
      if(format){
        formatStr = format
      }else {
        formatStr = 'yyyy-MM-dd hh:mm:ss'
      }
      return formatDate(date, formatStr)
    } else {
      return ''
    }
  },
  isLogin() {
    let token = localStorage.getItem('token')
    if (token) {
      return true
    } else {
      return false
    }
  },
  goLogin() {
    this.$router.push({
      path: '/login'
    })
  },
  /*
* 获取某个元素下标
* arr: 传入的数组
* obj: 需要获取下标的元素
* */
  getArrayIndex(arr, obj) {
    var i = arr.length
    while (i--) {
      if (arr[i] === obj) {
        return i
      }
    }
    return -1
  },
  uuid() {
    var s = []
    var hexDigits = "0123456789abcdef"
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = "4"
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = "-"
    var uuid = s.join("")
    return uuid
  },
  showPic(url, params) {
    if (url && params && url.indexOf('https://appsub.oss-cn-hangzhou.aliyuncs.com') != -1) {
      return url + params
    }
    return url
  }
}
