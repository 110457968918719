<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    let nowTime = new Date();
    if (localStorage.getItem("time")) {
      var cha = nowTime.getTime() - localStorage.getItem("time");
      console.log(cha);
      if (cha > 7200000) {
        this.$store.dispatch("LogOut");
        this.$router.push({ name: "index" });
        this.$message({
          message: "登录过期请重新登陆",
          type: "warning",
        });
        window.localStorage.clear();
      }
    }
  },
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f5f5f5;
}
</style>
